import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
   //live uat crm in  token
  //:string = "Bearer 72b987f7-3037-4747-92ef-a2ab2006f1ae";

  //local uat token    
  //bearer_token:string = "Bearer  d36a79f8-fe7a-4912-b3f9-9c25b8b4dc67";

 
    //local  token crm
 bearer_token:string = "Bearer  cd938db8-4e97-46a9-8950-417a0df32949";

 





// ad htmlpage location

//iap_loan_application_m







   










//send by crm
  constructor() { }
}
    



